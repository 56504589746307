<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Dinesh Jankie</h2>
          <p>
            Even voorstellen mijn naam is Dinesh en ik ben geboren en getogen in
            Den Haag. Ik ben een enthousiaste en zorgzame osteopaat.
          </p>
          <p>
            De werking van het menselijk lichaam heeft mij al vanaf een jonge
            leeftijd gefascineerd. Daarom ben ik in 2010 fysiotherapie gaan
            studeren aan de Hoge School Thim van der Laan te Nieuwegein. Na mijn
            fysiotherapie opleiding succesvol te hebben afgerond ben ik werkzaam
            geweest in meerdere eerstelijns praktijken, klinieken en voetbal
            clubs in de regio Haaglanden. Als fysiotherapeut had ik vaak het
            idee alleen symptomatisch te behandelen en was ik te weinig bezig
            met de oorzaak van de klacht, waardoor het resultaat van korte duur
            was.
          </p>
          <p>
            Dat is de reden dat ik in 2014 osteopathie ben gaan studeren aan de
            Internationale Academy Osteopathie (IAO). Daar leerde ik om het
            lichaam niet in delen te zien, maar te benaderen als één werkend
            organisme. Door deze holistische visie kreeg ik het inzicht dat de
            oorzaak van klachten vaak ergens anders zitten dan waar deze
            klachten ervaren worden.
          </p>
          <p>
            In het dagelijks leven hou ik mij graag bezig met sporten. Ik ben
            jaren actief geweest in het wedstrijdzwemmen en op dit moment ben ik
            veelvuldig aan het hardlopen.
          </p>
          <p>
            Ik hoop u binnenkort in de praktijk te mogen verwelkomen om op zoek
            te gaan naar de oorzaak van uw klacht.
          </p>
          <!-- <h3>Specialismen</h3>
          <p></p>
          <h3>Opleiding en Nascholing</h3>
          <ul></ul> -->

          <img src="../../assets/team/dinesh.png" alt="Dinesh" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
